import { useStore } from '@/store';
export default defineNuxtPlugin(nuxt => {
  const store = useStore(nuxt.$pinia);
  // The pinia or vuex instant is needed in order to access the store instance outside setup()
  
  addRouteMiddleware('my-middleware', async (to, from) => {
        // TODO:
        //console.log(to)
        if(store.languages){       
          var langPattern=/[\/][a-z]{2}[\/]/
          if(langPattern.test(to.fullPath)){
            var idioma = to.fullPath.substring(1,3)
            store.setIdioma(idioma) 
          }
          
       }
    }, { global: true });
});

// for non-global middleware use this: 


  // addRouteMiddleware('named-test', (to, from) => {
  //   console.log('this named middleware was added in a plugin')
  // })