import type { RouterConfig } from '@nuxt/schema'
import { languagesDefault } from "~/store";

export default {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => {
    var addRoutes = [..._routes]
    languagesDefault.forEach(lang => {
      if(lang.language != 'es')
      {
        var rutas = _routes
        rutas.forEach(ruta => {
          var r = {...ruta}
          r.path = "/" + lang.language + ruta.path
          r.name = lang.language + '-' + ruta.name
          addRoutes.push(r)
        })
      }
    })
    return addRoutes
  },
  scrollBehaviorType:"smooth"
} satisfies RouterConfig