<script setup lang="ts">
import { useStore } from './store/index'
const store = useStore();
const route = useRoute()
const headers = useRequestHeaders()

const compAppClass = computed(()=>{
  if (store.ModoAccesible){
    return 'accesible-mode';
  }
  return '';
});
console.log('Create APP')
console.log('route: '+route.fullPath)
const id = await useAsyncData("initNotaria", ()=>store.initNotaria(headers).then(() => true));





function createHead(){
  let head={}
  if(store.css_list){
    head.link=[]
    store.css_list.forEach(css =>{
      head.link.push({ rel: 'stylesheet', href: css })
    })
  }
  return head
}
useHead(createHead())

if(store.NOTARIA?.Personalizar?.UnderConstruction && store.token == null){
  if(route.name!='UnderConstruction'){
    navigateTo("/underconstruction")
  }
}


</script>
<template>
  <v-app id="app" :class="compAppClass">
    <NuxtLayout v-if="store.NOTARIA"/>
  </v-app>
</template>